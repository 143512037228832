import React, { ReactElement } from "react"
import { Header } from "../components/Header"
import { PrivacyPolicy } from "../components/policy/PrivacyPolicy"
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';

interface Props { }

function Policy(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Chính sách | cokhach.vn" />
      <Header />
      <PrivacyPolicy />
      <Footer />
    </>
  )
}

export default Policy
