import React from "react"
import { PageTitle } from "../parts/Title"

export function PrivacyPolicy() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <PageTitle>Chính sách bảo mật</PageTitle>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <p className="leading-relaxed text-base">
                cokhach.vn luôn coi việc bảo vệ thông tin cá nhân của Khách hàng
                (khách truy cập/ người dùng) là ưu tiên hàng đầu.
                <br />
                Các Khách hàng đồng ý sử dụng cokhach.vn sẽ mặc nhiên đồng ý với
                Chính sách bảo mật này. Chúng tôi sẵn sàng giải thích cho Khách
                hàng về các điều khoản. Mọi thắc mắc vui lòng liên hệ theo email
                info@cokhach.vn.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                1. Mục đích và phạm vi thu thập thông tin
              </h2>
              <p className="leading-relaxed text-base">
                <h3>1.1. Phạm vi thu thập thông tin</h3>
                Việc thu thập thông tin được thực hiện trên cơ sở Khách hàng tự
                cung cấp và đồng thuận cấp quyền để đăng ký tài khoản
                cokhach.vn, tùy từng thời điểm, thông tin thu thập sẽ bao gồm
                nhưng không giới hạn ở:
                <ul className="list-disc pl-5">
                  <li>
                    Thông tin cá nhân của Khách hàng như: Họ tên, Email, Số điện
                    thoại, Công ty / Doanh nghiệp
                  </li>
                  <li>
                    Thông tin liên kết tài khoản trên các nền tảng thứ ba
                    (Facebook, Google) như: các quyền truy cập thông tin cá nhân
                    (bao gồm bất kỳ thông tin cá nhân nào được Khách hàng công
                    khai) và xem email tài khoản (Facebook, Google) của Khách
                    hàng
                  </li>
                  <li>
                    Thông tin thanh toán: cokhach.vn chỉ lưu giữ thông tin chi
                    tiết về đơn hàng đã thanh toán của Khách hàng. Chúng tôi
                    không lưu giữ tất cả các thông tin về tài khoản ngân hàng
                    của Khách hàng
                  </li>
                  <li>
                    Dữ liệu sử dụng dịch vụ: Trong quá trình Khách hàng đăng ký
                    mua và sử dụng dịch vụ, chúng tôi lưu trữ có mã hoá bảo mật
                    thông tin về dịch vụ mà Khách hàng sử dụng và cách thức sử
                    dụng của Khách hàng. Khách hàng hiểu và chấp nhận rằng, bằng
                    cách sử dụng dịch vụ, các thông tin liên quan đến dự án, dữ
                    liệu khách mua tiềm năng thu thập được thông qua quảng cáo
                    sử dụng công cụ của cokhach.vn, các thông tin về landing
                    page được tạo bởi công cụ của cokhach.vn, tài khoản quảng
                    cáo (nếu có) mà Khách hàng đã nhập và thao tác trên nền tảng
                    của chúng tôi sẽ được mã hoá và lưu trữ trên máy chủ mà
                    cokhach.vn sử dụng để vận hành nền tảng{" "}
                  </li>
                </ul>
                <h3>1.2. Mục đích thu thập thông tin</h3>
                <ul className="list-disc pl-5">
                  <li>
                    Khởi tạo tài khoản và không gian làm việc trực tuyến cho
                    Khách hàng và đội nhóm (nếu có)
                  </li>
                  <li>
                    Cung cấp các dịch vụ, sản phẩm tuỳ biến theo nhu cầu của
                    Khách hàng, giúp Khách hàng bám sát và thực hiện các mục
                    tiêu Marketing của cá nhân / doanh nghiệp
                  </li>
                  <li>
                    Liên hệ xác nhận khi Khách hàng đăng ký sử dụng dịch vụ hoặc
                    xác lập giao dịch trên website hoặc ứng dụng cokhach.vn
                  </li>
                  <li>
                    Liên hệ để trao đổi thông tin sản phẩm/ dịch vụ chúng tôi
                    cung cấp và giải đáp thắc mắc của Khách hàng
                  </li>
                  <li>
                    Gửi thông tin về chính sách, điều khoản, dịch vụ của chúng
                    tôi cũng như những thay đổi về chính sách, dịch vụ
                  </li>
                  <li>Xử lý khiếu nại phát sinh, nâng cấp dịch vụ</li>
                </ul>
                Nếu người sử dụng không cung cấp thông tin hoặc cung cấp thông
                tin không chính xác, chúng tôi có quyền từ chối toàn bộ việc sử
                dụng dịch vụ (hoặc bất kỳ phần nào của dịch vụ).
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                2. Phạm vi sử dụng thông tin
              </h2>
              <p className="leading-relaxed text-base">
                2.1. cokhach.vn chỉ thu thập, xử lý và sử dụng thông tin của
                Khách hàng cho các mục đích quy định tại Mục 2 hoặc mục đích
                khác (nếu có) với điều kiện đã thông báo và được sự đồng ý của
                Khách hàng. Chúng tôi cam kết không tự ý truy cập, sử dụng,
                chuyển giao, cung cấp hoặc tiết lộ cho bên thứ 3 nào về thông
                tin của Khách hàng (bao gồm thông tin cá nhân, thông tin liên
                kết tài khoản trên các nền tảng thứ ba (Facebook, Google), thông
                tin thanh toán, dữ liệu sử dụng dịch vụ) trừ khi Khách hàng cho
                phép, hoặc việc này là yêu cầu pháp lý. cokhach.vn sẽ không tiết
                lộ thông tin của Khách hàng khi chưa xác định được những đề nghị
                của cơ quan pháp luật hoặc của người yêu cầu là hợp pháp theo
                qui định hiện hành.
              </p>
              <p className="leading-relaxed text-base">
                2.2. cokhach.vn có thể sử dụng thông tin của Khách hàng khi
                chúng tôi buộc phải tuân theo yêu cầu của pháp luật để bảo vệ
                quyền lợi và tài sản của chúng tôi, nhằm ngăn chặn sự gian lận
                hoặc các hoạt động phi pháp khác thông qua các dịch vụ của
                cokhach.vn. Điều này có thể bao gồm cả việc chia sẻ với các công
                ty, luật sư, đại lý, các phòng ban của Chính phủ.
              </p>
              <p className="leading-relaxed text-base">
                2.3. cokhach.vn có thể sử dụng thông tin về hoạt động tài khoản
                của Khách hàng (thông tin dưới dạng phân tích dữ liệu số hoá)
                nhằm mục đích nghiên cứu tính hiệu quả của dịch vụ, nghiên cứu
                thị trường, cải thiện phát triển sản phẩm/ dịch vụ và nỗ lực
                tiếp thị bán hàng của chúng tôi.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                3. Thời gian lưu trữ thông tin
              </h2>
              <p className="leading-relaxed text-base">
                Thông tin cá nhân của Khách hàng sẽ được lưu trữ theo thời gian
                và quy định của pháp luật và mục đích lưu trữ dữ liệu của chúng
                tôi.
                <br />
                Thông tin cá nhân của Khách hàng sẽ được lưu trữ cho đến khi có
                yêu cầu hủy bỏ tài khoản từ phía Khách hàng. Đối với các tài
                khoản đã đóng chúng tôi vẫn lưu trữ thông tin cá nhân và truy
                cập của khách hàng để phục vụ cho mục đích phòng chống gian lận,
                điều tra, giải đáp thắc mắc ... Các thông tin này sẽ được lưu
                trữ trong hệ thống máy chủ tối đa hai mươi tư (24) tháng. Hết
                thời hạn này, cokhach.vn sẽ tiến hành xóa vĩnh viễn thông tin cá
                nhân của khách hàng.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                4. Những người hoặc tổ chức có thể được tiếp cận với thông tin
                bảo mật
              </h2>
              <p className="leading-relaxed text-base">
                cokhach.vn và các bộ phận liên quan đến việc hỗ trợ và thực hiện
                hợp đồng, sử dụng và triển khai phần mềm.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                5. Thông tin công ty
              </h2>
              <p className="leading-relaxed text-base">
                Công ty TNHH Game Changer
                <br />
                MSDN: 0108341206
                <br />
                Địa chỉ trụ sở chính: Tầng 17, toà nhà HL Tower, Lô A2B, đường
                Duy Tân, phường Dịch Vọng Hậu, quận Cầu Giấy, TP Hà Nội.
                <br />
                Điện thoại: 024 66 873 572
                <br />
                Email: info@cokhach.vn
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                6. Cách thức chỉnh sửa dữ liệu cá nhân
              </h2>
              <p className="leading-relaxed text-base">
                Trường hợp Khách hàng muốn chỉnh sửa, xóa thông tin cá nhân,
                Khách hàng có thể tự đăng nhập vào tài khoản cokhach.vn và thao
                tác tại mục Thông tin tài khoản, hoặc yêu cầu cokhach.vn hỗ trợ
                chỉnh sửa. Hoặc nếu có bất cứ thắc mắc nào về điều khoản cá
                nhân, bảo mật, vui lòng gửi email cho chúng tôi theo địa chỉ:
                info@cokhach.vn
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                7. Đảm bảo an ninh Tài khoản và mật khẩu
              </h2>
              <p className="leading-relaxed text-base">
                Khách hàng cam kết áp dụng các quy định bảo mật thích hợp và cần
                thiết liên quan đến việc truy cập và sử dụng tài khoản và mật
                khẩu, đảm bảo quyền truy cập và sử dụng dịch vụ không vi phạm
                Quy định này; đồng thời đảm bảo sự tuân thủ của người sử dụng do
                mình quản lý. Khách hàng có nghĩa vụ thông báo cho cokhach.vn
                tất cả các vi phạm an ninh đối với mật khẩu và tài khoản, truy
                nhập trái phép vào nền tảng cokhach.vn ngay lập tức khi phát
                hiện ra hành vi vi phạm.
                <br />
                Trong suốt thời hạn thực hiện Thoả thuận này, mỗi Bên cam kết
                tuân thủ và không xâm phạm quyền đối với tài sản trí tuệ của Bên
                còn lại.
                <br />
                cokhach.vn cam kết không truy cập, tiết lộ tài khoản và mật
                khẩu, dữ liệu khách mua tiềm năng, hoặc thay đổi các thông tin
                tài khoản trừ khi:Theo yêu cầu của Khách hàng hoặc Theo yêu cầu
                của các cơ quan nhà nước có thẩm quyền. Trong trường hợp này,
                cokhach.vn sẽ ngay lập tức thông báo cho Khách hàng về việc cung
                cấp thông tin như vậy.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                8. Cam kết bảo mật thông tin Khách hàng
              </h2>
              <p className="leading-relaxed text-base">
                Khách hàng hiểu và chấp nhận rằng, bằng cách sử dụng dịch vụ,
                các thông tin liên quan đến dự án, dữ liệu khách mua tiềm năng
                thu thập được thông qua quảng cáo sử dụng công cụ của
                cokhach.vn, các thông tin về landing page được tạo bởi công cụ
                của cokhach.vn, tài khoản quảng cáo (nếu có) (sau đây gọi là
                "Thông tin") mà Khách hàng đã nhập và thao tác trên nền tảng của
                chúng tôi sẽ được mã hoá và lưu trữ trên máy chủ mà cokhach.vn
                sử dụng để vận hành nền tảng. <br />
                cokhach.vn đảm bảo và cam kết không truy cập, thay đổi Thông
                tin; chia sẻ, tiết lộ hoặc cung cấp cho bất kỳ bên thứ ba nào
                nào khác trừ khi được sự cho phép của Khách hàng. Đồng thời,
                cokhach.vn sẽ tiến hành các biện pháp cần thiết và trong phạm vi
                cho phép để đảm bảo sự bảo mật của các Thông tin nói trên.
                cokhach.vn sẽ ngay lập tức thông báo cho Khách hàng khi:
                <ul className="list-disc pl-5">
                  <li>
                    cokhach.vn được cảnh báo hoặc nghi ngờ đã hoặc đang có sự
                    xâm phạm đến sự bảo mật của Thông tin; hoặc
                  </li>
                  <li>
                    Được yêu cầu tiết lộ Thông tin theo quy định pháp luật hoặc
                    bởi cơ quan, tổ chức có thẩm quyền.
                  </li>
                </ul>
                Nghĩa vụ bảo mật của cokhach.vn không áp dụng đối với:
                <ul className="list-disc pl-5">
                  <li>
                    Những Thông tin đã trở nên công khai tại thời điểm tiết lộ
                    hoặc trở nên công khai một cách hợp pháp sau đó mà không
                    xuất phát từ việc vi phạm nghĩa vụ bảo mật của cokhach.vn;
                  </li>
                  <li>
                    Thông tin mà cokhach.vn đã có trước khi nhận được từ Khách
                    hàng với điều kiện nguồn của những thông tin này không bị
                    ràng buộc bởi bất kỳ thỏa thuận bảo mật, nghĩa vụ pháp lý,
                    hợp đồng hoặc ủy thác bảo mật nào với Khách hàng đối với
                    những thông tin đó.
                  </li>
                  <li>Thông tin được cho phép bằng văn bản của Khách hàng</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
